<template>
	<v-form class="mb-4 pb-4" ref="form" v-model="valid" lazy-validation @submit.prevent v-if="modelLoaded">
		<v-row class="pui-form-layout">
			<v-col cols="24">
				<v-row dense>
					<!-- COMPANYID -->
					<v-col cols="12">
						<pui-select
							:label="$t('form.operationsprofilecompany.companyid')"
							v-model="model"
							toplabel
							clearable
							:disabled="!isCreatingElement"
							modelName="company"
							:itemsToSelect="[{ id: model.companyid }]"
							:modelFormMapping="{ id: 'companyid' }"
							itemValue="id"
							itemText="name"
						></pui-select>
					</v-col>
				</v-row>
				<v-row dense>
					<!-- PROFILEID -->
					<v-col cols="12">
						<pui-field-set :title="$t('form.operationsprofilecompany.profiles')">
							<pui-multi-select
								itemValue="profile"
								itemText="name"
								v-model="profiles"
								:items="allProfiles"
								:itemsToSelect="model.profiles"
								:disabled="formDisabled"
								returnValue
							></pui-multi-select>
						</pui-field-set>
					</v-col>
				</v-row>
				<v-row dense>
					<v-col cols="6">
						<pui-date-field
							v-model="endusersuscription"
							:label="$t('form.operationsprofilecompany.endusersuscription')"
							:disabled="formDisabled"
							toplabel
							reactive
							clearable
						></pui-date-field>
					</v-col>
				</v-row>
			</v-col>
		</v-row>

		<!-- Page Footer -->
		<pui-form-footer v-if="!isModalDialog">
			<pui-form-footer-btns
				:formDisabled="formDisabled"
				:saveDisabled="saving"
				:saveAndNew="saveAndNew"
				:saveAndUpdate="saveAndUpdate"
				:save="customSave"
				:back="back"
			></pui-form-footer-btns>
		</pui-form-footer>
	</v-form>
	<pui-form-loading v-else></pui-form-loading>
</template>

<script>
import PuiFormMethodsMixin from 'pui9-mixins/PuiFormMethodsMixin';

export default {
	mixins: [PuiFormMethodsMixin],
	data() {
		return {
			modelName: 'operationsprofilecompany',
			originalProfiles: null,
			value: null,
			profiles: null
		};
	},
	methods: {
		afterGetData() {
			this.profiles = this.model.profiles;
			this.originalProfiles = this.model.profiles;
			this.deleteOne = true;
		},
		customSave() {
			if (this.profiles) {
				var profilesNames = this.profiles.map(function (item) {
					return item['profile'];
				});

				const profilesToDelete = this.originalProfiles ? this.originalProfiles.filter((p) => !profilesNames.includes(p)) : null;
				const profilesToAdd = this.originalProfiles ? profilesNames.filter((p) => !this.originalProfiles.includes(p)) : profilesNames;
				if (profilesToAdd) {
					profilesToAdd.forEach((v) => {
						(this.model.profile = v), this.insert(true, this.modelDependency.url.insert);
					});
				}
				if (profilesToDelete) {
					profilesToDelete.forEach((v) => {
						(this.model.profile = v), this.delete();
					});
				}
			}

			if (this.endusersuscription) {
				this.$puiRequests.postRequest(
					'/configportcompany/updatesuscription',
					{
						companyid: this.model.companyid,
						date: this.value
					},
					() => {
						this.$puiNotify.success(this.$t('pui9.save.success'));
						this.$store.dispatch('refreshAllConfigPortCompany');
						this.cleanAndBack();
					},
					(error) => {
						this.$store.dispatch('puiRequestShowServerError', { error: error, vue: this });
					}
				);
			}
			this.cleanAndBack();
		},
		delete() {
			const params = {
				companyid: this.model.companyid,
				profile: this.model.profile,
				deleteOne: true
			};
			this.$puiRequests.deleteRequest(
				'/' + this.modelName + '/delete',
				null,
				() => {
					this.$puiNotify.success(this.$t('pui9.save.success'));
				},
				(error) => {
					this.$store.dispatch('puiRequestShowServerError', { error: error, vue: this });
				},
				params
			);
		},
		cleanAndBack() {
			this.value = null;
			this.originalProfiles = null;
			this.profiles = null;
			this.back();
		}
	},
	computed: {
		allProfiles() {
			return this.$store.state.operationsuser && this.$store.state.operationsuser.puiprofile ? this.$store.state.operationsuser.puiprofile : [];
		},
		endusersuscription: {
			get() {
				if (this.$store.getters.getAllConfigPortCompany && !this.value) {
					const configportcompany = this.$store.getters.getAllConfigPortCompany.find((c) => c.companyid == this.model.companyid);
					this.value = configportcompany ? configportcompany.endusersuscription : null;
				}
				return this.value;
			},
			set(value) {
				this.value = value;
			}
		}
	},
	watch: {},
	created() {
		this.$store.dispatch('puiMultiSelectGetAllItems', { model: 'operationsuser', requestModel: 'puiprofile', vue: this });
		this.$store.dispatch('getAllPorcontrolProfileCompany', { model: 'operations', requestModel: 'profilecompany', vue: this });
		this.$store.dispatch('getAllConfigPortCompany'); //it lost that info when screen was refreshed
	},
	mounted() {}
};
</script>
